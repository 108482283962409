import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import "../style/privacy-policy.scss";

const PrivacyPolicyPage = ({ data, pageContext, path }) => {
  const { frontmatter } = data.markdownRemark;

  const content = frontmatter[pageContext.language || "en"];

  return (
    <Layout language={pageContext.language || "en"} path={path}>
      <div id="privacy-policy-page">
        <h1>{content.title}</h1>
        <p>{content.description}</p>
        <div id="chapters">
          <ol>
            {content.chapters.map((chapter) => (
              <>
                <li>{chapter.title}</li>
                <p dangerouslySetInnerHTML={{ __html: chapter.content }}></p>
              </>
            ))}
          </ol>
        </div>
      </div>
    </Layout>
  );
};

PrivacyPolicyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PrivacyPolicyPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(
      frontmatter: { en: { templateKey: { eq: "privacy-policy-page" } } }
    ) {
      frontmatter {
        en {
          title
          description
          chapters {
            title
            content
          }
        }
        it {
          title
          description
          chapters {
            title
            content
          }
        }
        es {
          title
          description
          chapters {
            title
            content
          }
        }
      }
    }
  }
`;
